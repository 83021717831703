import React, { Fragment, useEffect, useState } from 'react'
import '../styles/css/contacts.css'
import '../styles/css/users.css'
import { ReactComponent as Download } from '../assets/svg/download.svg'
import { ReactComponent as Inbox } from '../assets/svg/inbox.svg'
import { ReactComponent as ArrowLeft } from '../assets/svg/arrow-left.svg'
import api from '../api/axios'
import moment from 'moment'

const Users = () => {
  const [users, setUsers] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [view, setView] = useState<{ status: boolean; _id: string }>({
    status: false,
    _id: '',
  })
  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true)
      const res = await api('GET', 'allusers', {})
      setLoading(false)
      console.log(res)
      if (res.status === 200) {
        setUsers(res.data)
      }
    }
    fetchUsers()
  }, [])

  // format date to short format
  function formatDate(dateString: string): string {
    const date = moment(dateString)
    const formattedDate = date.format('DD, MMM, YYYY h:mm A')
    return formattedDate
  }
  if (loading) {
    return (
      <Fragment>
        <div className="loading">
          <div className="ring">
            Loading
            <span></span>
          </div>
        </div>
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        <div className="contacts users">
          {users.length !== 0 ? (
            view.status ? (
              <div className="user-view">
                <div className="user-view-wrapper">
                  <div className="user-view-header">
                    <ArrowLeft
                      className="userViewbackIc"
                      onClick={() => {
                        setView({ status: false, _id: '' })
                      }}
                    />
                    <h1>User</h1>
                  </div>
                  {users
                    .filter((user: any) => user._id === view._id)
                    .map((filteredUser: any) => (
                      <div key={filteredUser._id} className="users-view-body">
                        <div className="userview-details">
                          <p>
                            <strong
                              style={{
                                fontWeight: 'bold',
                                fontFamily: 'Apercu, Roboto',
                              }}
                            >
                              User ID:
                            </strong>{' '}
                            {filteredUser._id}
                          </p>
                        </div>
                        <div className="userview-details">
                          <p>
                            <strong
                              style={{
                                fontWeight: 'bold',
                                fontFamily: 'Apercu, Roboto',
                              }}
                            >
                              username:
                            </strong>{' '}
                            {filteredUser.username}
                          </p>
                        </div>
                        <div className="userview-details">
                          <p>
                            <strong
                              style={{
                                fontWeight: 'bold',
                                fontFamily: 'Apercu, Roboto',
                              }}
                            >
                              Email:
                            </strong>{' '}
                            {filteredUser.email}
                          </p>
                        </div>
                        <div className="userview-details">
                          <p>
                            <strong
                              style={{
                                fontWeight: 'bold',
                                fontFamily: 'Apercu, Roboto',
                              }}
                            >
                              Phone Number:
                            </strong>{' '}
                            {filteredUser.phonenumber}
                          </p>
                        </div>
                        <div className="userview-details">
                          <p>
                            <strong
                              style={{
                                fontWeight: 'bold',
                                fontFamily: 'Apercu, Roboto',
                              }}
                            >
                              Password:
                            </strong>{' '}
                            **************{' '}
                            <span
                              className="user-pass-insecure user-pass-insecure"
                              title="user password is secure"
                            >
                              secure
                            </span>
                          </p>
                          <button>request change</button>
                        </div>
                        <div className="userview-details">
                          <p>
                            <strong
                              style={{
                                fontWeight: 'bold',
                                fontFamily: 'Apercu, Roboto',
                              }}
                            >
                              Date Joined:
                            </strong>{' '}
                            {formatDate(filteredUser.createdAt)}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <div className="cWrapper">
                <div className="section-title">
                  <h1>Registered Users</h1>
                  <div className="sec-events">
                    <button className="dwld-table">download</button>
                    <div className="rows-per-page"></div>
                  </div>
                </div>
                <div className="table-wrap">
                  <table>
                    <thead>
                      <tr
                        style={{
                          backgroundColor: '#ff4380',
                          color: '#fff',
                          fontWeight: 'bold',
                        }}
                      >
                        <td>User ID</td>
                        <td>Username</td>
                        <td>Email</td>
                        <td>Phone</td>
                        <td>Date Joined</td>
                        <td>Actions</td>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((i: any) => {
                        return (
                          <tr
                            key={i._id}
                            onClick={() => {
                              setView({ status: true, _id: i._id })
                            }}
                          >
                            <td>{i._id}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                              {i.username}
                            </td>
                            <td>{i.email}</td>
                            <td>{i.phonenumber}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                              {formatDate(i.createdAt)}
                            </td>
                            <td>
                              <div className="actions-wrapp">
                                <Download className="report-action" />
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )
          ) : (
            <div className="noreportsmodal">
              <div className="norepmainwrapper">
                <div className="noreportswrap">
                  <Inbox className="inboxsvgicon" />
                  <h2>no users</h2>
                  <p>no user has registered to the system yet!</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    )
  }
}

export default Users
