import React, {
  FormEvent,
  Fragment,
  Suspense,
  useEffect,
  useState,
} from 'react'
import '../styles/css/products.css'
import { ReactComponent as Upload } from '../assets/svg/icon-download.svg'
import { ReactComponent as Pen } from '../assets/svg/pen.svg'
import { ReactComponent as Trash } from '../assets/svg/trash.svg'
import PlaceHolder from '../assets/images/kenyan-gift.jpeg'
import { ReactComponent as ArrowLeft } from '../assets/svg/arrow-left.svg'
import api from '../api/axios'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import ImageUpload from '../utils/ImageUpload'
import Slider from '../components/Slider'

interface Prod {
  _id: string
  arrimages: (string | File)[]
  title: string
  price: number
  stock: number
  description: string
  category: string
}

const Products = () => {
  const [loaded, setLoaded] = useState<boolean>(false)
  const [submit, setSubmit] = useState<boolean>(false)
  const [uploading, setUploading] = useState<boolean>(false)
  const [delloading, setDelloading] = useState<{
    [key: string]: boolean
  }>({})
  const [products, setProducts] = useState([])
  const [detailsView, setDetailsView] = useState<{
    state: boolean
    _id: string
  }>({ state: false, _id: '' })
  const [update, setUpdate] = useState<Prod>({
    _id: '',
    arrimages: [],
    title: '',
    price: 0,
    stock: 0,
    description: '',
    category: '',
  })

  const fetchProducts = async () => {
    const res = await api('GET', 'getproducts', {})
    if (res.status === 200) {
      setProducts(res.data)
    }
  }
  useEffect(() => {
    fetchProducts()
  }, [])

  const updateProduct = async (e: FormEvent) => {
    e.preventDefault()
    setSubmit(true)
    const res = await api('PUT', `updateproducts/${update._id}`, update)
    setSubmit(false)
    if (res.status === 200) {
      toast('Product updated successfully')
      fetchProducts()
    } else {
      toast('Error Updating Product: Retry')
    }
  }
  useEffect(() => {
    if (detailsView.state) {
      const [selected]: any = products.filter(
        (product: any) => product._id === detailsView._id
      )
      setUpdate(selected)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsView._id])

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()

    const files = Array.from(event.dataTransfer.files || [])
    setUpdate({
      ...update,
      arrimages: files,
    })

    if (files.length > 0) {
      const file: File = files[0]

      setUploading(true)
      const imageUrl = await ImageUpload(file)
      setUpdate({
        ...update,
        arrimages: [...update.arrimages, imageUrl],
      })
      setUploading(false)
    }
  }

  const handleInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const files: File[] = Array.from(e.target.files || [])
    setUpdate({
      ...update,
      [e.target.name]: files,
    })

    if (files.length > 0) {
      const file: File = files[0]

      setUploading(true)
      const imageUrl = await ImageUpload(file)
      setUpdate({
        ...update,
        arrimages: [...update.arrimages, imageUrl],
      })
      setUploading(false)
    }
  }

  const deleteProduct = async (_id: string) => {
    try {
      setDelloading((p) => ({
        ...p,
        [_id]: true,
      }))
      const res = await api('DELETE', `/deleteproduct/${_id}`)
      if (res.status === 200) {
        setDelloading((p) => ({
          ...p,
          [_id]: false,
        }))
        toast('product deleted successfully')
        fetchProducts()
      }
    } catch (error) {
      setDelloading((p) => ({
        ...p,
        [_id]: false,
      }))
    }
  }

  if (products.length === 0) {
    return (
      <Fragment>
        <div className="loading">
          <div className="ring">
            Loading
            <span></span>
          </div>
        </div>
      </Fragment>
    )
  }
  if (!detailsView.state) {
    return (
      <Fragment>
        <div className="products">
          <h1>Products</h1>
          <Suspense fallback={<h1>Loading...</h1>}>
            <div className="showcasecont">
              {products.map((i: any) => {
                return (
                  <div className="showcaseitem" key={i._id}>
                    <div
                      className={
                        loaded
                          ? 'showcaseitemtop'
                          : 'showcaseitemtop swcaseloading'
                      }
                    >
                      <img
                        className="swcaseimageprev"
                        onLoad={() => setLoaded(true)}
                        src={i.arrimages[0]}
                        loading="lazy"
                        alt=""
                      />
                      <div className="swcaseoverlay">
                        <div className="overlaytopswcase">
                          <div
                            className="swcseaddtocart"
                            onClick={() => {
                              setDetailsView({
                                state: true,
                                _id: i._id,
                              })
                            }}
                          >
                            <Pen className="plus" />
                            <p className="addtocartswcasetexts">Edit Details</p>
                          </div>
                          <div
                            className="swcdeleteproduct swcaseitemlike"
                            title="delete product"
                            style={
                              delloading[i._id] !== undefined
                                ? delloading[i._id]
                                  ? {
                                      backgroundColor: '#000',
                                      padding: '10px 20px',
                                    }
                                  : {}
                                : {}
                            }
                            onClick={() => {
                              deleteProduct(i._id)
                            }}
                          >
                            {delloading[i._id] !== undefined ? (
                              delloading[i._id] ? (
                                <div className="dot-flashing"></div>
                              ) : (
                                <Trash className="likeheart" />
                              )
                            ) : (
                              <Trash className="likeheart" />
                            )}
                          </div>
                        </div>
                        <div className="overlaybtm">
                          <div className="shcaseshopicon">
                            <img src={PlaceHolder} alt="" />
                          </div>
                          <div className="swprodprice">
                            <h3 className="prodnameswcase" title={i.title}>
                              {i.title}
                            </h3>
                            <p className="proswprice">
                              KES {i.price.toLocaleString()}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </Suspense>
        </div>
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        <div className="productdetail">
          <div className="productdetailheader">
            <ArrowLeft
              className="productViewbackIc"
              onClick={() => {
                setDetailsView({ state: false, _id: '' })
              }}
            />
            <h1>Product Details</h1>
          </div>
          <div className="productdetailbody">
            {update ? (
              <div key={update._id} className="products-view-body">
                <div className="prod-body-left">
                  <div className="prod-image-wrapper">
                    <Slider images={update.arrimages} />
                  </div>
                </div>
                <div className="prod-body-right">
                  <form
                    className="prod-edit-form"
                    onSubmit={(e) => {
                      updateProduct(e)
                    }}
                  >
                    <div className="prod-form-group">
                      <label htmlFor="Product Name">Product Name</label>
                      <br />
                      <input
                        type="text"
                        value={update.title}
                        className="prod-input-edit"
                        name="title"
                        placeholder="Product name e.g. AirForce 1 White Designer"
                        onChange={(e) => {
                          setUpdate({
                            ...update,
                            [e.target.name]: e.target.value,
                          })
                        }}
                      />
                    </div>

                    <div className="prod-form-group">
                      <div className="newprod-formGroup">
                        <label htmlFor="image">
                          Image (You can upload multiple images)
                        </label>
                        <br />
                        <div
                          className="input-field-row"
                          onDragOver={handleDragOver}
                          onDrop={handleDrop}
                        >
                          <div className="input-file-row">
                            <label
                              htmlFor="project_brief"
                              className="project_brief"
                            >
                              <div className="file-content">
                                <div className="icon">
                                  <Upload className="image-upload-icon" />
                                </div>
                                <div className="content-label">
                                  {update.arrimages.length > 0 ? (
                                    uploading ? (
                                      <p>uploading</p>
                                    ) : (
                                      update.arrimages
                                        .map((i: any) => {
                                          if (!i) {
                                            return null
                                          }
                                          if (typeof i === 'string') {
                                            return (
                                              <div
                                                className="files-images filenames-list"
                                                key={uuidv4()}
                                                style={{
                                                  backgroundColor: 'unset',
                                                }}
                                              >
                                                <img
                                                  src={i}
                                                  style={{
                                                    width: '30px',
                                                    height: '30px',
                                                  }}
                                                  alt=""
                                                />
                                              </div>
                                            )
                                          } else if (
                                            i &&
                                            typeof i.name !== 'undefined'
                                          ) {
                                            return (
                                              <span
                                                className="filenames-list"
                                                key={uuidv4()}
                                                title={i.name}
                                              >
                                                {i.name}
                                              </span>
                                            )
                                          } else {
                                            return null
                                          }
                                        })
                                        .filter(Boolean)
                                    )
                                  ) : (
                                    'Drag or browse your files here'
                                  )}
                                </div>
                              </div>
                              <div className="row-wrap">
                                <div id="filePush" className="button">
                                  upload
                                </div>
                              </div>
                            </label>
                            <input
                              className="file-field"
                              type="file"
                              name="arrimages"
                              multiple
                              onChange={handleInputChange}
                            />
                          </div>
                          <span id="id-err fileErr"></span>
                        </div>
                      </div>
                    </div>

                    <div className="prod-form-group">
                      <label htmlFor="Product Price">Product Price</label>
                      <br />
                      <input
                        type="number"
                        value={update.price}
                        className="prod-input-edit"
                        name="price"
                        placeholder="product price e.g. 1000"
                        onChange={(e) => {
                          setUpdate({
                            ...update,
                            [e.target.name]: e.target.value,
                          })
                        }}
                      />
                    </div>

                    <div className="prod-form-group">
                      <label htmlFor="Stock Size">
                        Stock Size (Quantity Available)
                      </label>
                      <br />
                      <input
                        type="number"
                        value={update.stock}
                        className="prod-input-edit"
                        name="stock"
                        placeholder="stock size e.g. 10"
                        onChange={(e) => {
                          setUpdate({
                            ...update,
                            [e.target.name]: e.target.value,
                          })
                        }}
                      />
                    </div>

                    <div className="newprod-formGroup">
                      <label htmlFor="category">Category</label>
                      <br />
                      <select
                        id="category"
                        name="category"
                        value={update.category}
                        onChange={(e) => {
                          setUpdate({
                            ...update,
                            [e.target.name]: e.target.value,
                          })
                        }}
                        required
                      >
                        <option value="">--Please choose a category--</option>
                        <option value="gift hampers">Gift Hampers</option>
                        <option value="arts">Arts</option>
                        <option value="jewelery">Jewelery</option>
                        <option value="flowers">Flowers</option>
                        <option value="watches">Watches</option>
                      </select>
                    </div>
                    <br />

                    <div className="prod-form-group">
                      <label htmlFor="Product Preview">Product Preview</label>
                      <br />
                      <textarea
                        value={update.description}
                        className="prod-input-edit prod-input-edit-textarea"
                        name="description"
                        onChange={(e) => {
                          setUpdate({
                            ...update,
                            [e.target.name]: e.target.value,
                          })
                        }}
                      ></textarea>
                    </div>

                    <div className="prod-ed-actions">
                      <button className="prod-update-btn" type="submit">
                        {submit ? (
                          <div className="dot-flashing"></div>
                        ) : (
                          'update'
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Products
