import React, { Fragment, useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Routes from './Routes'
import Modal from './components/Modal'
import { useAppSelector } from './state/hooks'
import nullchemy from './assets/images/nullchemy.png'

const App: React.FC = () => {
  const [mod, setMod] = useState(false)
  const [modcontent, setModContent] = useState({
    height: '400px',
    width: '600px',
    message: '',
  })
  const apierror: { state: number; message: string } | any = useAppSelector(
    (state) => state.apierror
  )
  useEffect(() => {
    if (apierror.state >= 400) {
      setMod(true)
      setModContent({
        height: '100px',
        width: '200px',
        message: apierror.message,
      })
    } else {
      setMod(false)
      setModContent({ ...modcontent, message: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apierror, apierror.state])

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768)
    }
    checkMobile()
    window.addEventListener('resize', checkMobile)
    return () => {
      window.removeEventListener('resize', checkMobile)
    }
  }, [])

  return (
    <Fragment>
      {isMobile ? (
        <div
          className="mobi-view"
          style={{
            position: 'relative',
            height: '100vh',
            textAlign: 'center',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <img src={nullchemy} alt="" />
            <p style={{ fontSize: '1.2rem', whiteSpace: 'nowrap' }}>
              Please View on Desktop
            </p>
            <br />
            <a
              style={{ marginTop: '1.5rem', whiteSpace: 'nowrap' }}
              href="https://nullchemy.com"
            >
              nullchemy group
            </a>
          </div>
        </div>
      ) : (
        <div className="app mobile-only">
          <ToastContainer />
          {mod && (
            <Modal onClose={() => setMod(false)}>
              <div
                className="modalPopCont"
                style={{ height: modcontent.height, width: modcontent.width }}
              >
                <p>{modcontent.message}</p>
              </div>
            </Modal>
          )}
          <Routes />
        </div>
      )}
    </Fragment>
  )
}

export default App
