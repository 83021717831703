import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

interface PDFGeneratorOptions {
  tableContent: HTMLElement
  fileName: string
}

const generatePDF = ({
  tableContent,
  fileName,
}: PDFGeneratorOptions): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (!tableContent) {
      reject(new Error('Table content is missing'))
      return
    }

    const currentDate = new Date().toISOString().slice(0, 10)

    const { scrollX, scrollY } = window
    window.scrollTo(0, 0)

    html2canvas(tableContent, {
      scrollX: 0,
      scrollY: 0,
      logging: true,
      scale: 2,
      useCORS: true,
      backgroundColor: null,
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF('l', 'mm', 'a4')
        const width = pdf.internal.pageSize.getWidth()
        const height = pdf.internal.pageSize.getHeight()

        pdf.addImage(imgData, 'PNG', 0, 0, width, height)
        pdf.save(`${fileName}_${currentDate}.pdf`)

        window.scrollTo(scrollX, scrollY)
        resolve()
      })
      .catch((error) => {
        window.scrollTo(scrollX, scrollY)
        reject(error)
      })
  })
}

export default generatePDF
