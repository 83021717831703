import React, { Fragment, useEffect, useState } from 'react'
import '../styles/css/contacts.css'
import '../styles/css/users.css'
import { ReactComponent as Download } from '../assets/svg/download.svg'
import { ReactComponent as Inbox } from '../assets/svg/inbox.svg'
import { ReactComponent as ArrowLeft } from '../assets/svg/arrow-left.svg'
import api from '../api/axios'
import moment from 'moment'
import PlaceHolder from '../assets/images/kenyan-gift.jpeg'

const Orders = () => {
  const [orders, setOrders] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [view, setView] = useState<{ status: boolean; _id: string }>({
    status: false,
    _id: '',
  })
  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true)
      const res = await api('GET', 'allorders', {})
      setLoading(false)
      if (res.status === 200) {
        setOrders(res.data)
      }
    }
    fetchOrders()
  }, [])

  // format date to short format
  function formatDate(dateString: string): string {
    const date = moment(dateString)
    const formattedDate = date.format('DD, MMM, YYYY h:mm A')
    return formattedDate
  }

  console.log(orders)

  return (
    <Fragment>
      <div className="contacts users">
        {orders.length !== 0 ? (
          view.status ? (
            <div className="user-view">
              <div className="user-view-wrapper">
                <div className="user-view-header">
                  <ArrowLeft
                    className="userViewbackIc"
                    onClick={() => {
                      setView({ status: false, _id: '' })
                    }}
                  />
                  <h1>Order</h1>
                </div>
                {orders
                  .filter((user: any) => user._id === view._id)
                  .map((filteredUser: any) => (
                    <div key={filteredUser._id} className="users-view-body">
                      <div className="userview-details">
                        <p>
                          <strong
                            style={{
                              fontWeight: 'bold',
                              fontFamily: 'Apercu, Roboto',
                            }}
                          >
                            Order ID:
                          </strong>{' '}
                          {filteredUser._id}
                        </p>
                      </div>
                      <div className="userview-details">
                        <p>
                          <strong
                            style={{
                              fontWeight: 'bold',
                              fontFamily: 'Apercu, Roboto',
                            }}
                          >
                            User ID:
                          </strong>{' '}
                          {filteredUser.user_id}
                        </p>
                      </div>
                      <div className="userview-details">
                        <p>
                          <strong
                            style={{
                              fontWeight: 'bold',
                              fontFamily: 'Apercu, Roboto',
                            }}
                          >
                            Updated At:
                          </strong>{' '}
                          {formatDate(filteredUser.updatedAt)}
                        </p>
                      </div>
                      <div className="userview-details">
                        <p>
                          <strong
                            style={{
                              fontWeight: 'bold',
                              fontFamily: 'Apercu, Roboto',
                            }}
                          >
                            Payment Method:
                          </strong>{' '}
                          {filteredUser.payment_method}
                        </p>
                      </div>
                      <div className="userview-details">
                        <p>
                          <strong
                            style={{
                              fontWeight: 'bold',
                              fontFamily: 'Apercu, Roboto',
                            }}
                          >
                            Order Status:
                          </strong>{' '}
                          {filteredUser.order_status}
                        </p>
                      </div>
                      <div className="userview-details">
                        <p>
                          <strong
                            style={{
                              fontWeight: 'bold',
                              fontFamily: 'Apercu, Roboto',
                            }}
                          >
                            Total Price:
                          </strong>{' '}
                          KES{' '}
                          {parseInt(filteredUser.total_price).toLocaleString()}
                        </p>
                      </div>
                      <div className="userview-details">
                        <p>
                          <strong
                            style={{
                              fontWeight: 'bold',
                              fontFamily: 'Apercu, Roboto',
                            }}
                          >
                            Delivery Location:
                          </strong>{' '}
                          {filteredUser.delivery_location}
                        </p>
                      </div>
                      <div className="userview-details">
                        <p>
                          <strong
                            style={{
                              fontWeight: 'bold',
                              fontFamily: 'Apercu, Roboto',
                            }}
                          >
                            Date Contacted:
                          </strong>{' '}
                          {formatDate(filteredUser.timestamp)}
                        </p>
                      </div>
                      <h1
                        style={{
                          fontWeight: 'bold',
                          fontFamily: 'Apercu, Roboto',
                        }}
                      >
                        Products
                      </h1>
                      <div className="order_products_details">
                        {filteredUser.products.map((i: any) => {
                          return (
                            <div className="showcaseitem" key={i._id}>
                              <div className="showcaseitemtop">
                                <img
                                  className="swcaseimageprev"
                                  src={i.product_image[0]}
                                  loading="lazy"
                                  alt=""
                                />
                                <div className="swcaseoverlay">
                                  <div className="overlaytopswcase">
                                    <div className="swcseaddtocart">
                                      <p
                                        className="addtocartswcasetexts"
                                        title={`Order Quantity: ${i.orderquantity}`}
                                      >
                                        Order Quantity:{' '}
                                        <strong>{i.orderquantity}</strong>
                                      </p>
                                    </div>
                                    <div className="swcseaddtocart">
                                      <p className="addtocartswcasetexts">
                                        Product ID:{' '}
                                        <strong>{i.product_id}</strong>
                                      </p>
                                    </div>
                                    <div className="swcseaddtocart">
                                      <p
                                        className="addtocartswcasetexts"
                                        title="this product has a custom request for it!"
                                      >
                                        Custom Request:{' '}
                                        <strong>{i.request}</strong>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="overlaybtm">
                                    <div className="shcaseshopicon">
                                      <img src={PlaceHolder} alt="" />
                                    </div>
                                    <div className="swprodprice">
                                      <h3
                                        className="prodnameswcase"
                                        title={i.product_name}
                                      >
                                        {i.product_name}
                                      </h3>
                                      <p className="proswprice">
                                        KES {i.product_price.toLocaleString()}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <div className="cWrapper">
              <div className="section-title">
                <h1>Orders</h1>
                <div className="sec-events">
                  <button className="dwld-table">download</button>
                  <div className="rows-per-page"></div>
                </div>
              </div>
              <div className="table-wrap">
                <table>
                  <thead>
                    <tr
                      style={{
                        backgroundColor: '#ff4380',
                        color: '#fff',
                        fontWeight: 'bold',
                      }}
                    >
                      <td>Order ID</td>
                      <td>Payment Method</td>
                      <td>Order Status</td>
                      <td>Price</td>
                      <td>Delivery Location</td>
                      <td>Date & Time</td>
                      <td>Actions</td>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((i: any) => {
                      const price = parseInt(i.total_price).toLocaleString(
                        'en-KE',
                        {
                          style: 'currency',
                          currency: 'KES',
                        }
                      )
                      console.log(price)

                      return (
                        <tr
                          key={i._id}
                          onClick={() => {
                            setView({ status: true, _id: i._id })
                          }}
                        >
                          <td>{i._id}</td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {i.payment_method}
                          </td>
                          <td>{i.order_status}</td>
                          <td>{price}</td>
                          <td>{i.delivery_location}</td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {formatDate(i.timestamp)}
                          </td>
                          <td>
                            <div className="actions-wrapp">
                              <Download className="report-action" />
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )
        ) : loading ? (
          <div className="noreportsmodal">
            <div className="norepmainwrapper">
              <div className="loading noreportswrap">
                <div className="ring">
                  Loading
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="noreportsmodal">
            <div className="norepmainwrapper">
              <div className="noreportswrap">
                <Inbox className="inboxsvgicon" />
                <h2>no Orders</h2>
                <p>no order has been made yet</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default Orders
