import React, { FormEvent, Fragment, useEffect, useState } from 'react'
import '../styles/css/contacts.css'
import '../styles/css/users.css'
import { ReactComponent as Inbox } from '../assets/svg/inbox.svg'
import { ReactComponent as Trash } from '../assets/svg/trash.svg'
import api from '../api/axios'
import { toast } from 'react-toastify'

const Admins = () => {
  const [admins, setAdmins] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [updateloading, setUpdateLoading] = useState(false)
  const [level, setLevel] = useState<{
    [key: string]: 0
  }>({})
  const fetchAdmins = async () => {
    setLoading(true)
    const res = await api('GET', 'getall', {})
    setLoading(false)
    if (res.status === 200) {
      setAdmins(res.data)
    }
  }
  useEffect(() => {
    fetchAdmins()
  }, [])
  const handleLevel = async (e: FormEvent, i: any) => {
    e.preventDefault()
    setUpdateLoading(true)

    const newlevel =
      level[i._id] !== undefined
        ? level[i._id] !== 0
          ? level[i._id]
          : parseInt(i.level)
        : parseInt(i.level)

    const res = await api('PUT', 'updateAdminLevel', {
      adminId: i._id,
      newLevel: newlevel,
    })
    setUpdateLoading(false)
    if (res.status === 200) {
      toast('updated admin level')
    } else {
      toast('something wrong happened!')
    }
  }

  const deleteAdmin = async (e: FormEvent, i: any) => {
    e.preventDefault()
    const res = await api('DELETE', 'deleteAdmin', { adminId: i._id })
    if (res.status === 200) {
      toast('Admin deleted successfully')
      fetchAdmins()
    } else {
      toast('something wrong happened!')
    }
  }

  if (loading) {
    return (
      <Fragment>
        <div className="loading">
          <div className="ring">
            Loading
            <span></span>
          </div>
        </div>
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        <div className="contacts users">
          {admins.length !== 0 ? (
            <div className="cWrapper">
              <div className="section-title">
                <h1>Administrators</h1>
                <div className="sec-events">
                  <button className="dwld-table">download</button>
                  <div className="rows-per-page"></div>
                </div>
              </div>
              <div className="table-wrap">
                <table>
                  <thead>
                    <tr
                      style={{
                        backgroundColor: '#ff4380',
                        color: '#fff',
                        fontWeight: 'bold',
                      }}
                    >
                      <td>Administrator ID</td>
                      <td>Full Name</td>
                      <td>Email</td>
                      <td>Level</td>
                      <td>Actions</td>
                    </tr>
                  </thead>
                  <tbody>
                    {admins.map((i: any) => {
                      console.log(level[i._id])

                      return (
                        <tr key={i._id}>
                          <td>{i._id}</td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {i.full_name}
                          </td>
                          <td>{i.email}</td>
                          <td>
                            <div className="tb-input-wrapper">
                              <form
                                action=""
                                className="level-form"
                                onSubmit={(e) => {
                                  handleLevel(e, i)
                                }}
                              >
                                <input
                                  className="lev-input"
                                  type="number"
                                  value={
                                    level[i._id] !== undefined
                                      ? level[i._id] !== 0
                                        ? level[i._id]
                                        : parseInt(i.level)
                                      : parseInt(i.level)
                                  }
                                  onChange={(e) => {
                                    setLevel((prevAdmins) => ({
                                      ...prevAdmins,
                                      [i._id]: parseInt(e.target.value),
                                    }))
                                  }}
                                />
                                <button
                                  type="submit"
                                  className="level-update-btn"
                                >
                                  {updateloading ? (
                                    <div className="dot-flashing"></div>
                                  ) : (
                                    'update'
                                  )}
                                </button>
                              </form>
                            </div>
                          </td>
                          <td>
                            <div className="actions-wrapp">
                              <Trash
                                onClick={(e) => {
                                  deleteAdmin(e, i)
                                }}
                                className="report-action"
                              />
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="noreportsmodal">
              <div className="norepmainwrapper">
                <div className="noreportswrap">
                  <Inbox className="inboxsvgicon" />
                  <h2>could not fetch administrators</h2>
                </div>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    )
  }
}

export default Admins
