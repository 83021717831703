type FormData = {
  login: { email: string; password: string }
  register: {
    full_name: string
    email: string
    password: string
    cpassword: string
    level: string
  }
  reset: { email: string }
}

export const checkinputs = (formType: string, data: FormData) => {
  const { login, register, reset } = data

  switch (formType) {
    case 'login':
      const { email: loginEmail, password: loginPassword } = login

      if (!loginEmail || !loginPassword) {
        return {
          state: false,
          message: 'Please provide both email and password for login.',
        }
      }
      return { state: true, message: '' }

    case 'register':
      const {
        full_name,
        email: regEmail,
        password,
        cpassword,
        level,
      } = register
      const passwordRegex =
        /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/

      if (!full_name || !regEmail || !password || !cpassword || !level) {
        return {
          state: false,
          message: 'All fields are required for registration.',
        }
      }
      if (!passwordRegex.test(password)) {
        return {
          state: false,
          message:
            'Password must be at least 8 characters long and contain at least one symbol and one uppercase letter.',
        }
      }
      if (password !== cpassword) {
        return { state: false, message: 'Passwords do not match.' }
      }
      return { state: true, message: '' }

    case 'reset':
      const { email: resetEmail } = reset
      if (!resetEmail) {
        return { state: false, message: 'Please fill out your email.' }
      }
      return { state: true, message: '' }

    default:
      return {
        state: false,
        message: 'Invalid form Type. Refresh the page to fix this.',
      }
  }
}
