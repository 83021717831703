import React, { useRef, useEffect } from 'react'
import Chart from 'chart.js/auto'

interface PieChartProps {
  data: number[]
  labels: string[]
}

const PieChart: React.FC<PieChartProps> = ({ data, labels }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const ctx = canvasRef.current?.getContext('2d')
    const michart = new Chart(ctx!, {
      type: 'pie',
      data: {
        labels,
        datasets: [
          {
            label: 'Pie Chart',
            data,
            backgroundColor: [
              '#FF5179',
              '#44C4FF',
              '#FFCB51',
              '#6BFF55',
              '#6A83FF',
              '#FF4444',
            ],
            borderColor: [
              '#FF5179',
              '#44C4FF',
              '#FFCB51',
              '#6BFF55',
              '#6A83FF',
              '#FF4444',
            ],
            borderWidth: 1,
            hoverOffset: 10,
          },
        ],
      },
    })
    return () => {
      michart.destroy()
    }
  }, [canvasRef, data, labels])

  return <canvas ref={canvasRef} />
}

export default PieChart
