import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAppSelector } from '../state/hooks'

const ProtectedRoutes = (): JSX.Element => {
  const isLogged = useAppSelector((state) => state.logged)
  const location = useLocation()
  return isLogged ? (
    <Outlet />
  ) : (
    <Navigate to="/auth" state={{ from: location }} replace />
  )
}

export default ProtectedRoutes
