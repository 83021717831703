import axios, { AxiosInstance, AxiosResponse } from 'axios'
import session from '../utils/session'
import { backend } from '../utils/backend'

const axiosInstance: AxiosInstance = axios.create({
  baseURL: backend(),
  withCredentials: true,
})

axiosInstance.interceptors.request.use(
  (config) => {
    const { accessToken } = session.get()
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use((response: AxiosResponse) => {
  return response
})

const api = async (
  method: string = 'GET',
  slug: string,
  data: object = {},
  headers: object = {}
): Promise<AxiosResponse> => {
  try {
    const config = {
      method: method,
      maxBodyLength: Infinity,
      url: backend() + slug,
      headers: headers,
      data: data,
      withCredentials: true,
    }
    const res = await axiosInstance(config)
    if (res.status && (res.status === 401 || res.status === 403)) {
      session.destroy()
    }
    return res
  } catch (error: any) {
    return Promise.reject(error)
  }
}

export default api
