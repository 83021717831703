import React, { FormEvent, Fragment, useEffect, useState } from 'react'
import '../styles/css/auth.css'
import Logo from '../assets/images/kenyan-gift.jpeg'
import { Link, useNavigate } from 'react-router-dom'
import api from '../api/axios'
import { checkinputs } from '../utils/inputscheck'
import { useAppDispatch } from '../state/hooks'
import { setTab } from '../state/actions/tabAction'
import { setIsLogged } from '../state/actions/loggedAction'

type FormData = {
  login: { email: string; password: string }
  register: {
    full_name: string
    email: string
    password: string
    cpassword: string
    level: string
  }
  reset: { email: string }
}

const Auth = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [view, setview] = useState('login')
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<FormData>({
    login: { email: '', password: '' },
    register: {
      full_name: '',
      email: '',
      password: '',
      cpassword: '',
      level: '3',
    },
    reset: { email: '' },
  })
  const [response, setResponse] = useState({ error: false, message: '' })

  const handleFormSubmit = async (
    e: FormEvent,
    endpoint: string,
    formType: keyof FormData
  ) => {
    e.preventDefault()
    //set loading to true
    setLoading(true)
    //inputs check
    const chkinp = checkinputs(formType, formData)
    if (!chkinp.state) {
      setLoading(false)
      setResponse({ error: true, message: chkinp.message })
    }
    try {
      const res: any = await api('POST', endpoint, formData[formType])
      setLoading(false)
      if (res.status >= '200' && res.status <= '299') {
        setResponse({ error: false, message: 'Success!' })
        if (formType !== 'login') {
          setview('login')
        }
        dispatch(setIsLogged(true))
        dispatch(setTab('dashboard'))
        navigate('/')
      }
    } catch (err: any) {
      console.log(err)
      if (
        err.response &&
        err.response.status >= '400' &&
        err.response.status <= '499'
      ) {
        dispatch(setIsLogged(false))
        setResponse({ error: true, message: err.response.data })
      } else if (
        err.response &&
        err.response.status >= '500' &&
        err.response.status <= '599'
      ) {
        dispatch(setIsLogged(false))
        setResponse({ error: true, message: 'Internal Server Error: 500' })
      }
    }
    clearFormData(formType)
  }

  const clearFormData = (formType: keyof FormData) => {
    setFormData({ ...formData, [formType]: {} as any })
  }

  // click to make chrome set the actual values on autofill (simulating user interaction on page)
  useEffect(() => {
    const divs = document.querySelectorAll('input')
    const randomIndex = Math.floor(Math.random() * divs.length)
    if (divs[randomIndex]) {
      divs[randomIndex].click()
    }
  }, [])
  return (
    <Fragment>
      <div
        className="auth"
        style={{
          background: `url(https://images.unsplash.com/photo-1454496522488-7a8e488e8606?q=80&w=2076&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`,
          backgroundSize: 'cover',
        }}
      >
        <div className="authContainer">
          <div className="authWrapper">
            {view === 'login' ? (
              <div className="authForm login">
                <div className="authformheader">
                  <Link to="/" className="headerLogoLink">
                    <img src={Logo} alt="" />
                  </Link>
                  <h2>Login</h2>
                </div>
                <p>{response.message}</p>
                <form
                  autoComplete="off"
                  className="authentificationform"
                  method="POST"
                  onSubmit={(e) => {
                    handleFormSubmit(e, 'login', 'login')
                  }}
                >
                  <div className="authformgroup">
                    <input
                      autoComplete="off"
                      type="email"
                      className="authinput"
                      name="email"
                      value={formData.login.email}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          login: {
                            ...formData.login,
                            [e.target.name]: e.target.value,
                          },
                        })
                      }}
                    />
                    <span className="floating-label">
                      Email <span>*</span>
                    </span>
                  </div>
                  <div className="authformgroup">
                    <input
                      type="password"
                      className="authinput"
                      name="password"
                      value={formData.login.password}
                      autoComplete="new-password"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          login: {
                            ...formData.login,
                            [e.target.name]: e.target.value,
                          },
                        })
                      }}
                    />
                    <span className="floating-label">
                      Password <span>*</span>
                    </span>
                  </div>
                  <p
                    className="passresetlink"
                    onClick={() => {
                      setview('resetpass')
                    }}
                  >
                    Forgot Password?
                  </p>
                  <p
                    className="passresetlink"
                    onClick={() => {
                      setview('register')
                    }}
                  >
                    Register?
                  </p>
                  <br />
                  <button type="submit" className="authsubBtn">
                    {loading ? <div className="dot-flashing"></div> : 'login'}
                  </button>
                </form>
              </div>
            ) : view === 'resetpass' ? (
              <div className="authForm login">
                <div className="authformheader">
                  <Link to="/" className="headerLogoLink">
                    <img src={Logo} alt="" />
                  </Link>
                  <h2>Reset password</h2>
                </div>
                <p>{response.message}</p>
                <form
                  autoComplete="off"
                  className="authentificationform"
                  method="POST"
                  onSubmit={(e) => {
                    handleFormSubmit(e, 'reset', 'reset')
                  }}
                >
                  <div className="authformgroup">
                    <input
                      autoComplete="off"
                      type="email"
                      className="authinput"
                      name="email"
                      value={formData.reset.email}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          reset: {
                            ...formData.reset,
                            [e.target.name]: e.target.value,
                          },
                        })
                      }}
                    />
                    <span className="floating-label">
                      Email <span>*</span>
                    </span>
                  </div>
                  <p
                    className="passresetlink"
                    onClick={() => {
                      setview('login')
                    }}
                  >
                    login
                  </p>
                  <button type="submit" className="authsubBtn">
                    {loading ? (
                      <div className="dot-flashing"></div>
                    ) : (
                      'reset password'
                    )}
                  </button>
                </form>
              </div>
            ) : view === 'register' ? (
              <div className="authForm login">
                <div className="authformheader">
                  <Link to="/" className="headerLogoLink">
                    <img src={Logo} alt="" />
                  </Link>
                  <h2>Register</h2>
                </div>
                <p>{response.message}</p>
                <form
                  autoComplete="off"
                  className="authentificationform"
                  method="POST"
                  onSubmit={(e) => {
                    handleFormSubmit(e, 'register', 'register')
                  }}
                >
                  <div className="authformgroup">
                    <input
                      autoComplete="off"
                      type="text"
                      className="authinput"
                      name="full_name"
                      value={formData.register.full_name}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          register: {
                            ...formData.register,
                            [e.target.name]: e.target.value,
                          },
                        })
                      }}
                    />
                    <span className="floating-label">
                      Full Name <span>*</span>
                    </span>
                  </div>
                  <div className="authformgroup">
                    <input
                      autoComplete="off"
                      type="email"
                      className="authinput"
                      name="email"
                      value={formData.register.email}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          register: {
                            ...formData.register,
                            [e.target.name]: e.target.value,
                          },
                        })
                      }}
                    />
                    <span className="floating-label">
                      Email <span>*</span>
                    </span>
                  </div>
                  <div className="authformgroup">
                    <input
                      type="password"
                      className="authinput"
                      name="password"
                      value={formData.register.password}
                      autoComplete="new-password"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          register: {
                            ...formData.register,
                            [e.target.name]: e.target.value,
                          },
                        })
                      }}
                    />
                    <span className="floating-label">
                      Password <span>*</span>
                    </span>
                  </div>
                  <div className="authformgroup">
                    <input
                      type="password"
                      className="authinput"
                      name="cpassword"
                      value={formData.register.cpassword}
                      autoComplete="new-password"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          register: {
                            ...formData.register,
                            [e.target.name]: e.target.value,
                          },
                        })
                      }}
                    />
                    <span className="floating-label">
                      Confirm Password <span>*</span>
                    </span>
                  </div>
                  <p
                    className="passresetlink"
                    onClick={() => {
                      setview('login')
                    }}
                  >
                    Login?
                  </p>
                  <br />
                  <button type="submit" className="authsubBtn">
                    {loading ? (
                      <div className="dot-flashing"></div>
                    ) : (
                      'register'
                    )}
                  </button>
                </form>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Auth
