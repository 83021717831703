import React, { useRef, useEffect } from 'react'
import Chart from 'chart.js/auto'
import '../styles/css/linechart.css'

interface LineChartProps {
  data: number[]
  labels: string[]
}

const LineChart: React.FC<LineChartProps> = ({ data, labels }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const chartInstance = useRef<Chart | null>(null)

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d')
      if (ctx) {
        if (chartInstance.current) {
          chartInstance.current.destroy()
        }

        chartInstance.current = new Chart(ctx, {
          type: 'line',
          data: {
            labels,
            datasets: [
              {
                label: 'Line Chart',
                data,
                fill: false,
                borderColor: [
                  '#FF5179',
                  '#44C4FF',
                  '#FFCB51',
                  '#6BFF55',
                  '#6A83FF',
                  '#FF4444',
                ],
                tension: 0.1,
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        })
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy()
      }
    }
  }, [data, labels])

  return <canvas className="dashlnchart" ref={canvasRef} />
}

export default LineChart
