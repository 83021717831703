import React, { Fragment } from 'react'

const Notifications = () => {
  return (
    <Fragment>
      <div className="notifications">
        <h1>Notifications</h1>
      </div>
    </Fragment>
  )
}

export default Notifications
