import React, { Fragment, useEffect, useState } from 'react'
import '../styles/css/contacts.css'
import '../styles/css/users.css'
import { ReactComponent as Download } from '../assets/svg/download.svg'
import { ReactComponent as Trash } from '../assets/svg/trash.svg'
import { ReactComponent as Inbox } from '../assets/svg/inbox.svg'
import { ReactComponent as ArrowLeft } from '../assets/svg/arrow-left.svg'
import api from '../api/axios'
import contactsdata from '../data/contacts.json'
import moment from 'moment'
import generatePDF from '../utils/generatePDF'
import { toast } from 'react-toastify'

const Contacts = () => {
  const [contacts, setContacts] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const tableRef = React.useRef<HTMLTableElement>(null)
  const [view, setView] = useState<{ status: boolean; _id: string }>({
    status: false,
    _id: '',
  })
  useEffect(() => {
    const fetchContacts = async () => {
      setContacts(contactsdata.data)
      try {
        setLoading(true)
        const res = await api('GET', 'getcontacts', {})
        setLoading(false)
        if (res.status === 200) {
          setContacts(res.data)
        }
      } catch (error) {
        setLoading(false)
      }
    }
    fetchContacts()
  }, [])

  // format date to short format
  function formatDate(dateString: string): string {
    const date = moment(dateString)
    const formattedDate = date.format('DD, MMM, YYYY h:mm A')
    return formattedDate
  }

  // generate PDF
  const handlePDFGeneration = () => {
    if (tableRef.current) {
      generatePDF({ tableContent: tableRef.current, fileName: 'contacts' })
        .then(() => {
          toast('PDF generated successfully!')
          // Handle success
        })
        .catch((error) => {
          toast('Could Not Create your PDF')
          console.error('PDF generation failed:', error)
          // Handle error
        })
    }
  }
  if (loading) {
    return (
      <Fragment>
        <div className="loading">
          <div className="ring">
            Loading
            <span></span>
          </div>
        </div>
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        <div className="contacts users">
          {contacts.length !== 0 ? (
            view.status ? (
              <div className="user-view">
                <div className="user-view-wrapper">
                  <div className="user-view-header">
                    <ArrowLeft
                      className="userViewbackIc"
                      onClick={() => {
                        setView({ status: false, _id: '' })
                      }}
                    />
                    <h1>Contact</h1>
                  </div>
                  {contacts
                    .filter((user: any) => user._id === view._id)
                    .map((filteredUser: any) => (
                      <div key={filteredUser._id} className="users-view-body">
                        <div className="userview-details">
                          <p>
                            <strong
                              style={{
                                fontWeight: 'bold',
                                fontFamily: 'Apercu, Roboto',
                              }}
                            >
                              Contact ID:
                            </strong>{' '}
                            {filteredUser._id}
                          </p>
                        </div>
                        <div className="userview-details">
                          <p>
                            <strong
                              style={{
                                fontWeight: 'bold',
                                fontFamily: 'Apercu, Roboto',
                              }}
                            >
                              Full Name:
                            </strong>{' '}
                            {filteredUser.full_name}
                          </p>
                        </div>
                        <div className="userview-details">
                          <p>
                            <strong
                              style={{
                                fontWeight: 'bold',
                                fontFamily: 'Apercu, Roboto',
                              }}
                            >
                              Email:
                            </strong>{' '}
                            {filteredUser.email}
                          </p>
                        </div>
                        <div className="userview-details">
                          <p>
                            <strong
                              style={{
                                fontWeight: 'bold',
                                fontFamily: 'Apercu, Roboto',
                              }}
                            >
                              Phone Number:
                            </strong>{' '}
                            {filteredUser.phone_no}
                          </p>
                        </div>
                        <div className="userview-details">
                          <p>
                            <strong
                              style={{
                                fontWeight: 'bold',
                                fontFamily: 'Apercu, Roboto',
                              }}
                            >
                              Website:
                            </strong>{' '}
                            <a
                              href={filteredUser.website}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {filteredUser.website}
                            </a>
                          </p>
                        </div>
                        <div className="userview-details">
                          <p>
                            <strong
                              style={{
                                fontWeight: 'bold',
                                fontFamily: 'Apercu, Roboto',
                              }}
                            >
                              Subject:
                            </strong>{' '}
                            {filteredUser.subject}
                          </p>
                        </div>
                        <div
                          className="userview-details"
                          style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}
                        >
                          <h1
                            style={{
                              fontWeight: 'bold',
                              fontFamily: 'Apercu, Roboto',
                            }}
                          >
                            Message
                          </h1>
                          <p>{filteredUser.message}</p>
                        </div>
                        <div className="userview-details">
                          <p>
                            <strong
                              style={{
                                fontWeight: 'bold',
                                fontFamily: 'Apercu, Roboto',
                              }}
                            >
                              Date Contacted:
                            </strong>{' '}
                            {formatDate(filteredUser.timestamp)}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <div className="cWrapper">
                <div className="section-title">
                  <h1>Contacts</h1>
                  <div className="sec-events">
                    <button
                      className="dwld-table"
                      onClick={handlePDFGeneration}
                    >
                      download
                    </button>
                    <div className="rows-per-page"></div>
                  </div>
                </div>
                <div className="table-wrap">
                  <table ref={tableRef}>
                    <thead>
                      <tr
                        style={{
                          backgroundColor: '#ff4380',
                          color: '#fff',
                          fontWeight: 'bold',
                        }}
                      >
                        <td>ID</td>
                        <td>Name</td>
                        <td>Email</td>
                        <td>Subject</td>
                        <td>Message</td>
                        <td>Date & Time</td>
                        <td>Actions</td>
                      </tr>
                    </thead>
                    <tbody>
                      {contacts.map((i: any) => {
                        return (
                          <tr
                            key={i._id}
                            onClick={() => {
                              setView({
                                status: true,
                                _id: i._id,
                              })
                            }}
                          >
                            <td>{i._id}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                              {i.full_name}
                            </td>
                            <td>{i.email}</td>
                            <td>{i.subject}</td>
                            <td>
                              <span>{i.message}</span>
                            </td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                              {formatDate(i.timestamp)}
                            </td>
                            <td>
                              <div className="actions-wrapp">
                                <Download className="report-action" />
                                <Trash className="report-action" />
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )
          ) : (
            <div className="noreportsmodal">
              <div className="norepmainwrapper">
                <div className="noreportswrap">
                  <Inbox className="inboxsvgicon" />
                  <h2>no Contacts</h2>
                  <p>your inbox is clean</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    )
  }
}

export default Contacts
