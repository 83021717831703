import React, { Fragment } from 'react'
import '../styles/css/footer.css'

const Footer = () => {
  return (
    <Fragment>
      <div className="footer">
        <p>
          copyright &copy; {new Date().getFullYear()} |{' '}
          <a href="https://nullchemy.com/" target="_blank" rel="noreferrer">
            nullchemy inc
          </a>
        </p>
      </div>
    </Fragment>
  )
}

export default Footer
