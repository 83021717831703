type Action = {
  type: string
  payload?: object
}
const apiErrReducer = (
  state: object = { state: 100, message: '' },
  action: Action
) => {
  switch (action.type) {
    case 'SETAPIERROR':
      return action.payload
    default:
      return state
  }
}

export default apiErrReducer
