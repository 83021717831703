import { combineReducers } from 'redux'
import tabReducer from './tabReducer'
import apiErrReducer from './apiErrReducer'
import loggedReducer from './loggedReducer'

const allReducers = combineReducers({
  tab: tabReducer,
  apierror: apiErrReducer,
  logged: loggedReducer,
})

export type allReducerss = ReturnType<typeof allReducers>

export default allReducers
