import Cookies from 'js-cookie'

const getsession = () => {
  return {
    accessToken: Cookies.get('accessToken'),
  }
}

const destroysession = () => {
  Cookies.remove('accessToken')
}

const session = {
  get: getsession,
  destroy: destroysession,
}

export default session
