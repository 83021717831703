import React, { FormEvent, Fragment, useEffect, useState } from 'react'
import '../styles/css/newproduct.css'
import { ReactComponent as Upload } from '../assets/svg/icon-download.svg'
import api from '../api/axios'
import { useAppDispatch } from '../state/hooks'
import { setApiErr } from '../state/actions/apiErrAction'
import ImageUpload from '../utils/ImageUpload'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'

interface ProdTypes {
  title: string
  arrimages: File[]
  stock: number | undefined
  description: string
  price: string | number | readonly string[] | undefined
  category: string
}

const NewProduct = () => {
  const dispatch = useAppDispatch()
  const [uploading, setUploading] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<ProdTypes>({
    title: '',
    arrimages: [],
    stock: undefined,
    description: '',
    price: undefined,
    category: '',
  })

  const handleNewProd = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault()

    try {
      setLoading(true)
      const res = await api('POST', 'postproducts', data)
      setLoading(false)
      if (res.status >= 400) {
        dispatch(setApiErr({ state: res.status, message: res.data.message }))
        toast('There was an error Posting the Product: Retry')
      } else {
        toast('Product Posted Successfully')
        setData({
          title: '',
          arrimages: [],
          stock: 0,
          description: '',
          price: undefined,
          category: '',
        })
      }
    } catch (error) {
      console.error('Error occurred:', error)
      setLoading(false)
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setData({
      ...data,
      arrimages: Array.from(event.dataTransfer.files || []),
    })
  }
  useEffect(() => {
    const uploadfile = async () => {
      setUploading(true)
      const images = []
      if (data.arrimages.length > 0) {
        for (let i = 0; i < data.arrimages.length; i++) {
          if (typeof data.arrimages[i] === 'string') {
            images.push(data.arrimages[i])
          } else {
            images.push(await ImageUpload(data.arrimages[i]))
          }
        }
      }
      setData({ ...data, arrimages: images })
      setUploading(false)
    }

    uploadfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.arrimages.length])

  return (
    <Fragment>
      <div className="newproduct">
        <h1>New Product</h1>
        <div className="newproduct-container">
          <div className="newproductwrapper">
            <form
              onSubmit={(e) => handleNewProd(e)}
              className="newproduct-form"
            >
              <div className="newprod-formGroup">
                <label htmlFor="title">Title</label>
                <br />
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={data.title}
                  placeholder="Product name e.g. AirForce 1 White Designer"
                  onChange={(e) => {
                    setData({ ...data, [e.target.name]: e.target.value })
                  }}
                  required
                />
              </div>

              <div className="newprod-formGroup">
                <label htmlFor="image">
                  Images (You can upload multiple images)
                </label>
                <br />
                <div
                  className="input-field-row"
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <div className="input-file-row">
                    <label htmlFor="project_brief" className="project_brief">
                      <div className="file-content">
                        <div className="icon">
                          <Upload className="image-upload-icon" />
                        </div>
                        <div className="content-label">
                          {data.arrimages.length > 0 ? (
                            uploading ? (
                              <p>uploading...</p>
                            ) : (
                              data.arrimages
                                .map((i: any) => {
                                  if (!i) {
                                    return null
                                  }
                                  if (typeof i === 'string') {
                                    return (
                                      <div
                                        className="files-images filenames-list"
                                        key={uuidv4()}
                                        style={{ backgroundColor: 'unset' }}
                                      >
                                        <img
                                          src={i}
                                          style={{
                                            width: '30px',
                                            height: '30px',
                                          }}
                                          alt=""
                                        />
                                      </div>
                                    )
                                  } else if (
                                    i &&
                                    typeof i.name !== 'undefined'
                                  ) {
                                    return (
                                      <span
                                        className="filenames-list"
                                        key={uuidv4()}
                                        title={i.name}
                                      >
                                        {i.name}
                                      </span>
                                    )
                                  } else {
                                    return null
                                  }
                                })
                                .filter(Boolean)
                            )
                          ) : (
                            'Drag or browse your files here'
                          )}
                        </div>
                      </div>
                      <div className="row-wrap">
                        <div id="filePush" className="button">
                          upload
                        </div>
                      </div>
                    </label>
                    <input
                      className="file-field"
                      type="file"
                      name="arrimages"
                      multiple
                      onChange={(e) => {
                        setData({
                          ...data,
                          [e.target.name]: Array.from(e.target.files || []),
                        })
                      }}
                    />
                  </div>
                  <span id="id-err fileErr"></span>
                </div>
              </div>

              <div className="newprod-formGroup">
                <label htmlFor="Stock Size">
                  Stock Size (Quantity Available)
                </label>
                <br />
                <input
                  type="number"
                  id="stock"
                  name="stock"
                  value={data.stock}
                  placeholder="stock size e.g. 10"
                  onChange={(e) => {
                    setData({ ...data, [e.target.name]: e.target.value })
                  }}
                  required
                />
              </div>

              <div className="newprod-formGroup">
                <label htmlFor="description">Description</label>
                <br />
                <textarea
                  id="description"
                  name="description"
                  value={data.description}
                  placeholder="Product description"
                  onChange={(e) => {
                    setData({ ...data, [e.target.name]: e.target.value })
                  }}
                  required
                ></textarea>
              </div>

              <div className="newprod-formGroup">
                <label htmlFor="price">Price</label>
                <br />
                <input
                  type="number"
                  id="price"
                  name="price"
                  value={data.price}
                  placeholder="product price e.g. 1000"
                  onChange={(e) => {
                    setData({ ...data, [e.target.name]: e.target.value })
                  }}
                  required
                />
              </div>

              <div className="newprod-formGroup">
                <label htmlFor="category">Category</label>
                <br />
                <select
                  id="category"
                  name="category"
                  value={data.category}
                  onChange={(e) => {
                    setData({ ...data, [e.target.name]: e.target.value })
                  }}
                  required
                >
                  <option value="">--Please choose a category--</option>
                  <option value="gift hampers">Gift Hampers</option>
                  <option value="arts">Arts</option>
                  <option value="jewelery">Jewelery</option>
                  <option value="flowers">Flowers</option>
                  <option value="watches">Watches</option>
                </select>
              </div>

              <button className="newprodsub-btn" type="submit">
                {loading ? <div className="dot-flashing"></div> : 'submit'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default NewProduct
