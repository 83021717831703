import React, { useRef, useEffect } from 'react'
import Chart from 'chart.js/auto'

type DoughnutChartProps = {
  data: number[]
  labels: string[]
}

const DoughnutChart: React.FC<DoughnutChartProps> = ({ data, labels }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const ctx = canvasRef.current?.getContext('2d')

    const chart = new Chart(ctx!, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data,
            backgroundColor: [
              '#FF5179',
              '#44C4FF',
              '#FFCB51',
              '#6BFF55',
              '#6A83FF',
              '#FF4444',
            ],
            hoverOffset: 10,
          },
        ],
        labels,
      },
      options: {
        cutout: '50%',
        responsive: true,
        maintainAspectRatio: false,
      },
    })

    return () => {
      chart.destroy()
    }
  }, [data, labels])

  return <canvas ref={canvasRef} />
}

export default DoughnutChart
